import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { StyledSection, Inliner } from "../styles/Styles.js";
import { withNamespaces, NamespacesConsumer } from "react-i18next";
import { Markup } from "interweave";
import { connect } from "react-redux";
import axios from "axios";

class About extends Component {
  componentDidMount() {
    if (this.props.aboutData.length === 0) {
      axios
        .get("https://oedesign.pro/backend/api/about/")
        .then(json => this.props.onDataLoaded({ aboutData: json.data }));
    }
  }

  render() {
    const { lng, aboutData } = this.props;
    let container = "",
      container2 = "",
      container3 = "",
      container4 = "",
      container5 = "";
    let {
      content_pl,
      content_en,
      content2_pl,
      content2_en,
      content3_pl,
      content3_en,
      content4_pl,
      content4_en,
      content5_pl,
      content5_en,
      content4_title_en,
      content4_title_pl,
      content5_title_pl,
      content5_title_en
    } = aboutData;

    if (lng.includes("pl")) {
      container = <Markup content={content_pl} />;
      container2 = <Markup content={content2_pl} />;
      container3 = <Markup content={content3_pl} />;
      if (content4_pl) {
        container4 = (
          <Inliner>
            <div className="flexo smaller">
              <h3>{content4_title_pl}</h3>
            </div>
            <div className="flexo">
              <Markup content={content4_pl} />
            </div>
          </Inliner>
        );
      }
      if (content5_pl) {
        container5 = (
          <Inliner>
            <div className="flexo smaller">
              <h3>{content5_title_pl}</h3>
            </div>
            <div className="flexo">
              <Markup content={content5_pl} />
            </div>
          </Inliner>
        );
      }
    } else {
      container = <Markup content={content_en} />;
      container2 = <Markup content={content2_en} />;
      container3 = <Markup content={content3_en} />;
      if (content4_pl) {
        container4 = (
          <Inliner>
            <div className="flexo smaller">
              <h3>{content4_title_en}</h3>
            </div>
            <div className="flexo">
              <Markup content={content4_en} />
            </div>
          </Inliner>
        );
      }
      if (content5_en) {
        container5 = (
          <Inliner>
            <div className="flexo smaller">
              <h3>{content5_title_en}</h3>
            </div>
            <div className="flexo">
              <Markup content={content5_en} />
            </div>
          </Inliner>
        );
      }
    }

    return (
      <StyledSection page="about">
        <NamespacesConsumer>
          {(t, { i18n }) => (
            <React.Fragment>
              <Helmet>
                <title>
                  {t("PAGE_TITLE")} - {t("ABOUTUS")}
                </title>
                <meta
                  name="description"
                  content={t("PAGE_TITLE") + t("ABOUTUS")}
                />
              </Helmet>
            </React.Fragment>
          )}
        </NamespacesConsumer>
        <img
          src="images/oe_dp_grafika_6.jpg"
          alt="About pictogram"
          className="image-center"
          onLoad={this.handleImageLoaded}
        />
        <Inliner>
          <NamespacesConsumer>
            {(t, { i18n }) => (
              <React.Fragment>
                <div className="flexo smaller">
                  <h3>{t("WHOWEARE_LIST")}</h3>
                </div>
                <div className="flexo">{container}</div>
              </React.Fragment>
            )}
          </NamespacesConsumer>
        </Inliner>
        <Inliner>
          <NamespacesConsumer>
            {(t, { i18n }) => (
              <React.Fragment>
                <div className="flexo smaller">
                  <h3>{t("OFFER_LIST")}</h3>
                </div>
                <div className="flexo">{container2}</div>
              </React.Fragment>
            )}
          </NamespacesConsumer>
        </Inliner>
        <Inliner>
          <NamespacesConsumer>
            {(t, { i18n }) => (
              <React.Fragment>
                <div className="flexo smaller">
                  <h3>{t("MISSION_LIST")}</h3>
                </div>
                <div className="flexo">{container3}</div>
              </React.Fragment>
            )}
          </NamespacesConsumer>
        </Inliner>
        {container4}
        {container5}
      </StyledSection>
    );
  }
}

const mapStateToProps = state => ({
  menuOpened: state.menuOpened,
  pageLoading: state.pageLoading,
  aboutData: state.aboutData
});

const mapDispatchToProps = dispatch => ({
  onMenuOpen: menuOpened => {
    dispatch({
      type: "TOGGLE_MENU",
      payload: menuOpened
    });
  },
  onPageLoaded: e => {
    dispatch({
      type: "TOGGLE_LOADING",
      payload: e
    });
  },
  onDataLoaded: e => {
    dispatch({
      type: "UPDATE_ABOUT",
      payload: e
    });
  }
});

const AboutTranslated = withNamespaces("translation")(About);
const StoredAbout = connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutTranslated);
export default StoredAbout;
