import React, { Component } from "react";
import "babel-polyfill";
import NavbarTranslated from "./components/Navbar";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import Footer from "./components/Footer";
import Home from "./components/Home";
import { StyledApp } from "./styles/Styles.js";
import StoredAbout from "./components/About";
import StoredOffer from "./components/Offer";
import StoredContact from "./components/Contact";
import { connect } from "react-redux";
import disableScroll from "disable-scroll";
import { withNamespaces } from "react-i18next";

function NoMatch({ location }) {
  return <Redirect to="/" />;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500);
  }

  render() {
    const { menuOpened, lng, location } = this.props;
    const { onScolling } = this.state;

    if (menuOpened) {
      disableScroll.on();
    } else {
      disableScroll.off();
    }

    if (location.pathname === "/contact" && lng.includes("pl")) {
      return <Redirect to="/kontakt" />;
    } else if (location.pathname === "/services" && lng.includes("pl")) {
      return <Redirect to="/uslugi" />;
    } else if (location.pathname === "/about-us" && lng.includes("pl")) {
      return <Redirect to="/o-nas" />;
    } else if (location.pathname === "/kontakt" && lng.includes("en")) {
      return <Redirect to="/contact" />;
    } else if (location.pathname === "/uslugi" && lng.includes("en")) {
      return <Redirect to="/services" />;
    } else if (location.pathname === "/o-nas" && lng.includes("en")) {
      return <Redirect to="/about-us" />;
    }

    return (
      <StyledApp loading={this.state.isLoading} manuopened={menuOpened}>
        <NavbarTranslated scrolled={onScolling} />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about-us" component={StoredAbout} />
          <Route path="/services" component={StoredOffer} />
          <Route path="/contact" component={StoredContact} />
          <Route path="/o-nas" component={StoredAbout} />
          <Route path="/uslugi" component={StoredOffer} />
          <Route path="/kontakt" component={StoredContact} />
          <Route component={NoMatch} />
        </Switch>
        <Footer page={this.props.location.pathname} />
      </StyledApp>
    );
  }
}

const mapStateToProps = state => ({
  menuOpened: state.menuOpened,
  pageLoading: state.pageLoading
});

const mapDispatchToProps = dispatch => ({
  onMenuOpen: menuOpened => {
    dispatch({
      type: "TOGGLE_MENU",
      payload: menuOpened
    });
  }
});

const AppTranslated = withNamespaces("translation")(App);
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppTranslated)
);
