import { createStore, compose, applyMiddleware } from 'redux'
import componentsReducer from '../reducers/components'
import thunk from 'redux-thunk'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(componentsReducer, compose(
    applyMiddleware(thunk),
    composeEnhancers()
  )
)

export default store
