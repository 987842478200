import React from "react";
import ReactDOM from "react-dom";
import AppTranslated from "./App";
import { ThemeProvider } from "styled-components";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { GlobalStyle } from "./styles/Styles.js";
import "./i18n";
import WebFont from "webfontloader";
import store from "./store/store";
import { Provider } from "react-redux";
import 'whatwg-fetch';

WebFont.load({
  google: {
    families: ["Open Sans:300,400,600,700,800", "sans-serif"]
  }
});

ReactDOM.render(
  <Provider store={store}>
    <React.Fragment>
      <ThemeProvider theme={{ default: true }}>
        <GlobalStyle />
      </ThemeProvider>
      <Router>
        <AppTranslated />
      </Router>
    </React.Fragment>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
