const initialState = {
  menuOpened: false,
  menuClosing: false,
  pageLoading: true,
  contactData: [],
  offerData: [],
  aboutData: []
}

const componentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_MENU':
      return { ...state,
        menuOpened: action.payload.menuOpened,
        menuClosing: action.payload.menuClosing
      }
    case 'TOGGLE_LOADING':
      return action.payload
    case 'UPDATE_CONTACT':
      return { ...state,
        contactData: action.payload.contactData
      }
    case 'UPDATE_OFFER':
      return { ...state,
        offerData: action.payload.offerData
      }
    case 'UPDATE_ABOUT':
      return { ...state,
        aboutData: action.payload.aboutData
      }
    default:
      return state
  }
}

export default componentsReducer