import styled from 'styled-components'
import { Navbar, Nav, ControlLabel, FormControl, Checkbox, Button } from 'react-bootstrap'
import { createGlobalStyle, css } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    color: #fff;
    user-select: none;
    font-size: 17.5px;
    overflow-x: hidden;
  }
  body{
    color: #fff
    background-color: #000;
    font-family: 'Open Sans', sans-serif;
    font-size: 17.5px;
    overflow-x: hidden;
  }
`

const StyledCheckbox = styled(Checkbox)`
  label{
    padding-left: 0;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  input[type=checkbox]{
    width: 0;
    height: 0;
    overflow: hidden;
    font-size: 0;
    position: absolute;
    transform: scale(0);
  }
  i{
    float: left;
    width: 12px;
    height: 12px;
    background: ${ props => props.checked ? '#fae10b' : 'transparent' };
    border: 1px solid #fff;
    border-radius: 3px;
    display: inline-block;
    margin-right: 10px;
    border-color: ${ props => props.checked ? '#fae10b' : '#fff' };
  }
`

const Logotype = styled.div`
  width: 120px;
  height: 110px;
  background: url(images/oe_designpro_logo_bialynapis.svg);
  background-size: 210px auto;
  background-position: center center;
  display: block;
  position: relative;
  left: 7px;
  top: 3px;
  @media (min-width: 1450px)  {
    ${ props => props.sticky && css`
      position: fixed;
      left: auto;
      z-index: 1;
      top: 27px;
      margin-left: 7px;
    `}
  }
  @media (min-width: 1135px) and (max-width: 1366px){
    ${ props => props.sticky && css`
      position: fixed;
      left: 52px;
      z-index: 1;
      top: 27px;
    `}
  }
  @media (max-width: 750px){
    margin: auto;
    background-size: 230px auto;
    top: 10px;
    left: auto;
  }
`

const StyledButton = styled(Button)`
  &&{
    background-color: transparent;
    border-color: #fff;
    border-radius: 0;
    color: #fae10b;
    outline: none;
    ${ props => props.formdisabled === 'disabled' && css`
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    `}
    &:active{
      outline: none;
      background-color: transparent;
    }
    &:focus{
      outline: none;
      background-color: transparent;
      border-color: #fae10b;
    }
  }
`

const StyledControllabel = styled(ControlLabel)`
  color: #fae10b !important;
  display: block;
  margin-top: 20px;
  &:first-of-type{
    margin-top: 0
  }
`

const StyledFormControl = styled(FormControl)`
  border-radius: 0;
  outline: none;
  background-color: #000;
  height: 26px;
  color: #fff;
  box-shadow: none !important;
  border-color: #fff;
  resize: vertical;
  padding: ${ props => props.componentClass !== 'textarea' ? '0px 9px' : '4px 9px' };
  &:focus{
    border-color: #fff;
  }
  @media (max-width: 480px){
    font-size: 16px;
    &:focus {
      font-size: 16px;
    }
  }
`

const Inliner = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
  ${ props => (props.page === 'offer' || props.page === 'about') && css`
    align-items: center;
  `}
  margin-bottom: 37px;
  &:last-of-type{
    margin-bottom: 0;
    .flexo{
      .image-center{
        margin-bottom: 0;
      }
    }
  }
  &.first{
    margin-top: 60px;
    @media (max-width: 750px){
      margin-top: 82px;
    }
  }
  .flexo{
    flex: 0 0 64%;
    @media (max-width: 750px){
      flex: 0 0 100%;
    }
    &.smaller{
      flex: 0 0 36%;
      @media (max-width: 750px){
        flex: 0 0 100%;
      }
      ${ props => props.page === 'offer' && css`
        font-size: 19px;
        letter-spacing: 0.7px;
        h3{
          margin-top: -33px;
          @media (max-width: 1366px){
            max-width: 220px;
            margin-top: -56px;
          }
          @media (max-width: 750px){
            max-width: none;
            margin-top: -54px;
          }
        }
      `}
    }
  }
  @media (max-width:750px){
    &:last-of-type{
      padding-bottom: 0;
    }
  }
  h3{
    text-transform: uppercase;
    color: #fae10b;
    font-weight: 700;
    margin: 0;
    letter-spacing: 1px;
    font-size: 19px;
    @media (max-width: 980px){
      float: none;
      width: 100%;
      margin-bottom: 38px;
    }
    ${ props => (props.page === 'offer' || props.page === 'about-us') && css`
      font-size: 19px;
      letter-spacing: 0.7px;
    `}
  }
  h1.title{
    text-transform: uppercase;
    color: #fae10b;
    font-size: 20px;
    display: block;
    margin: 0;
    margin-bottom: 28px;
    font-weight: 700;
  }
  h2.title{
    text-transform: uppercase;
    color: #fae10b;
    font-size: 20px;
    display: block;
    margin: 0;
    margin-bottom: 28px;
    font-weight: 700;
  }
  .lefto{
    line-height: 22px;
    .lefto-more{
      display: block;
      margin-top: 11px;
    }
  }
  .righto{
    label{
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 4px;
    }
    @media (max-width: 980px){
      float: none;
      width: 100%;
      margin-top: 60px;
    }
  }
  p{
    font-size: 17px;
    .inline-icon{
      width: calc(100% - 50px);
      margin-left: 50px;
      position: relative;
    }
    img{
      width: calc(100% - 50px);
      margin-left: 50px;
    }
    @media (max-width: 980px){
      float: none;
      width: 100%;
      img{
        width: 100%;
        margin-left: 0;
      }
    }
    ${ props => props.page === 'offer' && css`
      width: 64%;
      margin: 0;
      img{
        width: 100%;
        margin: 0;
      }
    `}
  }
  &:after{
    content: "";
    display: table;
    clear: both;
  }
  @media (max-width:1366px){
    margin-bottom: 55px;
  }
  @media (max-width:830px){
    h3{
      width: 100%;
      float: none;
    }
    p{
      width: 100%;
      float: none;
      img{
        width: 100%;
        margin-left: 0;
      }
    }
  }
  @media (max-width:750px){
    h3{
      margin-bottom: 25px;
    }
    p{
      margin-left: 117px;
      width: auto;
      margin-bottom: 0;
    }
    margin-bottom: 72px;
    ${ props => props.page === 'offer' && css`
      padding-bottom: 49px;
    `}
  }
  @media (max-width:550px){
    p{
      margin-left: 0;
    }
  }
  .has-error{
    .form-control{
      border-color: #fae10b;
    }
  }
`

const StyledFooter = styled.footer `
  color: #747474;
  padding-bottom: 0;
  font-size: 12px;
  ${ props => props.page === '/' && css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 0;
  `}
  .first-footer{
    padding-left: 19px;
  }
  .logo-small{
    width: 100px;
    border: 0;
  }
  .container-fluid{
    box-sizing: border-box;
    @media (max-width: 1920px){
      padding-left: 60px;
      padding-right: 60px;
    }
    @media (max-width: 750px){
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media (max-width: 750px){
    padding-bottom: 10px;
    .first-footer{
      font-size: 13px;
      white-space: nowrap;
    }
    .second-footer{
      padding-left: 52px;
      padding-right: 0;
    }
  }
  @media (max-width: 745px){
    .first-footer{
      padding-top: 40px;
    }
  }
  @media (max-width: 435px){
    .first-footer{
      white-space: normal;
      padding-top: 22px;
      padding-right: 0;
      width: 60%;
      font-size: 11px;
    }
    .second-footer{
      width: 40%;
      padding-left: 0;
    }
  }
  .second-footer{
    text-align: right;
    padding-right: 9px;
  }
  @media (min-width: 1921px){
    max-width: 1850px;
    margin: auto;
  }
`

const StyledNavbar = styled(Navbar)`
  &&{
    padding: 0;
    background-color: #000;
    border-radius: 0;
    min-height: 136px;
    border: 0;
    padding-top: 9px;
    .container-fluid{
      padding-left: 45px;
      padding-right: 45px;
      @media (max-width:480px){
        padding-right: 19px;
      }
    }
    .navbar-collapse{
      @media (max-width:750px){
        border: none;
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #fae10b;
        left: 100%;
        margin-left: 0;
        margin-right: 0;
        z-index: 1;
        padding-top: 24px;
        padding-right: 50px;
        width: 100%;
        &.collapse.in{
          left: 0;
          transition: left 0.4s;
        }
      }
      ${ props => props.menuopened === 'true' && css`
        .menu-container{
          opacity: 0;
          transition: opacity 0.2s;
        }
        @media (min-width:751px){
          border: none;
          position: fixed;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: #fae10b;
          left: 100%;
          margin-left: 0;
          margin-right: 0;
          z-index: 1;
          padding-top: 24px;
          padding-right: 13px;
          width: auto;
          &.collapse.in{
            left: 58%;
            transition: left 0.4s;
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
          }
        }
        @media (max-width:480px){
          padding-right: 19px;
        }
        &.collapse.in{
          .menu-container{
            animation: showMenuContainer 0.1s 0.4s ease-out;
            animation-fill-mode: forwards;
            transition: opacity 0.2s;
            @keyframes showMenuContainer{
              100%{
                opacity: 1;
              }
            }
          }
        }
      `}
      ${ props => props.closing === 'true' && css`
        border: none;
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #fae10b;
        left: 100%;
        margin-left: 0;
        margin-right: 0;
        z-index: 1;
        padding-top: 24px;
        padding-right: 13px;
        width: auto;
        left: 58%;
        height: 100vh !important;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        opacity: 1;
        transition: none;
        animation: hideThismenu 0.3s;
        animation-fill-mode: forwards;
        @keyframes hideThismenu{
          100%{
            left: 100%;
          }
        }
        .menu-container{
          opacity: 0;
        }
        @media (max-width:750px){
          border: none;
          position: fixed;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: #fae10b;
          margin-left: 0;
          margin-right: 0;
          z-index: 1;
          padding-top: 24px;
          padding-right: 13px;
          width: auto;
          left: 0;
          box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
          display: block;
          opacity: 1;
        }
      `}
    }
    .language-switch{
      position: absolute;
      right: 0;
      padding-right: 55px;
      margin-top: 20px;
      display: block;
      font-size: 13px;
      button{
        cursor: pointer;
        background: transparent;
        text-transform: uppercase;
        font-weight: bold;
        border: 0;
        outline: none;
        &.current{
          color: #fae10b;
        }
      }
      &:after{
        content: "";
        display: table;
        clear: both;
      }
      ${ props => props.menuopened === 'true' && css`
        color: #7e7005;
        @media (max-width:750px){
          right: auto;
          position: relative;
          font-size: 35px;
          margin-left: 58px;
          display: inline-block;
          color: #000;
          button{
            margin-right: 28px;
            font-weight: 600;
            &.current{
              color: #000;
              font-weight: 800;
            }
          }
        }
        @media (min-width:751px){
          right: auto;
          left: 0;
          font-size: 24px;
          padding-right: 0;
          margin-left: 49px;
          margin-top: 36px;
          button{
            margin-right: 10px;
            font-weight: 600;
            &.current{
              color: #000;
              font-weight: 800;
            }
          }
        }
        @media (max-width:750px){
          text-align: left;
          margin-left: 0;
          margin-top: 15px;
          width: 217px;
          margin-left: 65px;
          button{
            padding: 0;
            font-weight: 400;
          }
        }
        @media (max-height:420px){
            margin-top: 31px;
        }
      `}
    }
    @media (max-width:768px){
      .navbar-brand{
        float: none;
        display: block;
        img{
          margin: auto;
        }
      }
    }
    @media (max-width:768px){
      .navbar-toggle{
        border-color: transparent;
        position: fixed;
        z-index: 2;
        right: 30px;
        &:hover, &:focus, &:active{
          background-color: transparent;
          .icon-bar{
            background-color: #000;
          }
        }
        .icon-bar{
          background-color: #000;
          width: 45px;
          height: 4px;
          margin-bottom: 6px;
          &:last-of-type{
            display: none;
          }
          ${ props => props.menuopened === 'true' && css`
            transform: rotate(45deg);
            &:nth-of-type(3) {
              transform: rotate(-45deg);
              margin-top: -17px;
              @media (max-width:480px){
                margin-top: -9px;
              }
            }
          `}
        }
        &.collapsed{
          position: relative;
          right: 0;
          &:hover, &:focus, &:active{
            background-color: transparent;
            .icon-bar{
              background-color: #fae10b;
            }
          }
          .icon-bar{
            background-color: #fae10b;
          }
        }
      }
    }
    @media (max-width:480px){
      .navbar-toggle{
        margin-top: 0;
        right: 0;
        &.collapsed{
          right: 13px;
        }
        .icon-bar{
          width: 45px;
          margin-bottom: 6px;
          height: 4px;
        }
      }
    }
    ${ props => props.sticky2 === 'true' && css`
      .navbar-toggle{
        border-color: transparent;
        position: relative;
        z-index: 2;
        right: 2px;
        top: 19px;
        display: block;
        &:hover, &:focus, &:active{
          background-color: transparent;
          .icon-bar{
            background-color: #000;
          }
        }
        .icon-bar{
          background-color: #000;
          width: 45px;
          height: 4px;
          margin-bottom: 6px;
          box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
          &:last-of-type{
            display: none;
          }
        }
        &.collapsed{
          position: fixed;
          right: 43px;
          top: 46px;
          @media (max-width:768px){
            right: 45px;
            margin-right: 0;
          }
          @media (max-width:480px){
            right: 32px;
          }
          &:hover, &:focus, &:active{
            background-color: transparent;
            @media (max-width:480px){
              background-color: transparent;
            }
            .icon-bar{
              background-color: #fae10b;
            }
          }
          .icon-bar{
            background-color: #fae10b;
          }
        }
      }
    `}
    ${ props => props.menuopened === 'true' && css`
        .navbar-toggle{
          display: block;
          position: fixed;
          right: 33px;
          @media (max-width:480px){
            right: 8px;
          }
          z-index: 2;
          top: 61px;
          border: 0;
          transform: scale(0.8);
          &:hover, &:focus, &:active{
            background-color: transparent;
            .icon-bar{
              background-color: #000;
            }
          }
          .icon-bar{
            width: 55px;
            height: 4px;
            margin-bottom: 13px;
            background-color: #000;
            transform: rotate(45deg);
            box-shadow: none;
            &:last-of-type{
              display: none;
            }
            &:nth-of-type(3) {
              transform: rotate(-45deg);
              margin-top: -17px;
            }
          }
        }
    `}
  @media (max-height: 420px){
    ${ props => props.menuopened === 'true' && css`
      .navbar-collapse.collapse.in{
        padding-top: 0;
      }
    `}
  }
  @media (max-height: 420px){
    ${ props => props.menuopened === 'true' && css`
      .navbar-toggle{
        top: 28px;
      }
    `}
    }
  }
`

const StyledOEI = styled.div`
  background: url(images/oe_industry_logo_biale.svg);
  width: 79px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: 11px -10px;
  display: inline-block;
  background-size: 100% auto;
  position: relative;
  top: -22px;
  @media (max-width:745px){
    top: -37px;
    width: 115px;
    background-position: 30px -10px;
    margin-right: 9px;
  }
  @media (max-width:745px){
    top: 0;
  }
  @media (max-width:480px){
    width: 79px;
    background-position: 21px 10px;
  }
`

const MenuLogotypes = styled.div`
  display: ${ props => props.menuopened === 'true' ? 'block' : 'none' };
  ${ props => props.menuopened === 'true' && css`
    position: fixed;
    right: 54px;
    bottom: 21px;
    .menu-logotype{
      width: 70px;
      height: 60px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: -19px -11px;
      background-size: 115px;
      background-image: url(images/oe_designpro_logo_czarne.svg);
      opacity: 0.5;
      transition: opacity 0.2s;
      &.second{
        background-image: url(images/oe_industry_logo_czarne.svg);
        background-position: -19px -13px;
      }
      &:hover{
        opacity: 1;
      }
    }
    @media (min-width:751px){
      right: 64px;
    }
    @media (max-height:620px){
      .menu-logotype{
        transform: scale(0.8);
      }
    }
    @media (max-width:480px){
      right: 33px;
    }
    /*
    @media (max-height:480px) and (max-width: 750px){
      bottom: auto;
      position: relative;
      right: auto;
      text-align: right;
    }
    */
  `}
`

const Styled404 = styled.div`
  font-size: 40px;
  display: block;
  text-align: center;
  margin: 150px 0;
`

const StyledPreload = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
`

const StyledSection = styled.section `
  display: block;
  max-width: 1051px;
  margin: 25px auto 80px auto;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  .form-group{
    &:last-of-type{
      margin-bottom: 0;
    }
  }
  ${ props => props.page === 'offer' && css`
    margin-top: 77px;
  `}
  a{
    color: #fae10b;
  }
  @media (max-width:1366px){
    max-width: 750px;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: unset;
  }
  @media (max-width:750px){
    max-width: none;
    margin: 53px 84px 221px 82px;
    box-sizing: border-box;
    padding-left: 0;
    padding-right: 0;
    overflow: visible;
  }
  @media (max-width:480px){
    margin: 53px 25px 221px 25px;
  }
  ${ props => props.page !== 'home' && css`
    @media (max-width:750px){
      margin-bottom: 52px;
    }
  `}
  .home-intro{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 60vh;
    max-width: 80vw;
    .image-center{
      margin: 0;
      height: 100%;
      width: auto;
      max-width: none;
      max-height: none;
    }
  }
  .aftersend{
    display: inline-block;
    margin-top: 20px;
    color: #fae10b;
    font-size: 14px;
    font-weight: 500;
  }
  ${ props => props.page === 'contact' && css`
    margin-top: 77px;
  `}
  ${ props => props.page === 'home' && css`
    margin: 0 auto 0 auto;
    @media (min-width:1366px){
      height: 70vh;
    }
    @media (max-width:1366px){
      margin-top: 136px;
      margin-bottom: 178px;
    }
    @media (max-width:1024px){
      margin-top: 174px;
      margin-bottom: 215px;
    }
    @media (max-width:750px){
      margin-top: 189px;
      margin-bottom: 189px;
      margin-right: 0;
    }
    @media (max-width:480px){
      margin-top: 30px;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-bottom: 30px;
    }
  `}
  .image-center{
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto 40px auto;
    @media (max-width:1366px){
      margin-bottom: 72px;
    }
    &.show-mobile{
      display: none;
    }
    @media (max-width:750px){
      max-width: 100%;
      margin-bottom: 34px;
      ${ props => props.page === 'home' && css`
        margin-bottom: 20px;
        max-width: none;
        width: 1400px;
        &.show-mobile{
          display: block;
          height: 20vh;
          width: auto;
          margin-bottom: 48px;
          margin-left: 0;
        }
        &.hide-mobile{
          max-width: 100%;
        }
      `}
    }
    @media (max-width:480px){
      &.hide-mobile{
        display: none;
      }
    }
  }
  .logo-title{
    color: #fae10b;
    text-transform: uppercase;
    font-weight: 700;
    position: absolute;
    font-size: 49px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    @media (max-width:480px){
      font-size: 25px;
      line-height: 35px;
    }
  }
  .form-lower{
    color: #7b7b7b;
    font-weight: 400;
    font-size: 14px;
  }
  .error-info{
    color: #fae10b;
    font-size: 14px;
  }
  @media (max-width:1366px){
    .home-intro{
      height: 36vh;
      padding-right: 10%;
    }
  }
  @media (max-width:1024px){
    .home-intro{
      height: 25vh;
      max-width: none;
      padding-right: 0;
      width: 75vw;
      .image-center{
        max-width: none;
      }
    }
  }
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    .home-intro{
      width: 58vw;
    }
  }
  @media (max-width:480px){
    .home-intro{
      background-image: url(images/home-responsive.jpg);
      background-size: auto 70%;
      background-repeat: no-repeat;
      background-position: left top;
      position: fixed;
      height: 280px;
      width: 100%;
      left: 20px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      max-width: none;
      z-index: 11;
      .logo-title{
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 0;
        top: auto;
        transform: none;
      }
    }
  }
  @media (max-width:800px){
    .home-intro{
      .logo-title{
        font-size: 25px;
      }
    }
  }
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    .home-intro{
      margin-top: 40px;
      .logo-title{
        font-size: 20px;
      }
    }
  }
`

const StyledWrapper = styled.div `
  display: block;
  background: red;
  max-width: 1100px;
  margin: auto;
`

const StyledApp = styled.div `
  max-width: 1980px;
  margin: auto;
  opacity: ${ props => props.loading ? 0 : 1 };
  transition: opacity 0.4s ease-in;
`

const StyledNav = styled(Nav)`
  &&{
    margin-top: 53px;
    opacity: 1;
    transition: opacity 0.2s;
    ${ props => props.sticky === 'true' && css`
      opacity: 0;
    `}
    li{
      font-size: 1rem;
      font-weight: 600;
      a{
        color: #fff;
        padding-right: 35px;
        h1{
          font-size: 1rem;
          margin: 0;
          line-height: 20px;
          padding: 0;
          font-weight: 600;
        }
      }
      &.last{
        a{
          padding-right: 21px;
        }
      }
      &.active > a{
        background-color: #000;
        color: #fae10b;
        &:focus, &:hover{
          background-color: #000;
          color: #fae10b;
        }
      }
      &.current{
        a{
          color: red;
        }
      }
      @media (min-width:750px){
        &:hover{
          a{
            color: #fae10b;
          }
        }
      }
    }
    ${ props => props.menuopened === 'true' && css`
        opacity: 1;
        text-align: right;
        backface-visibility: hidden;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 65px;
        top: 153px;
        @media (max-width: 750px){
          top: 180px;
        }
        li{
          font-size: 50px;
          margin-bottom: 41px;
          position: relative;
          display: block;
          float: none;
          backface-visibility: hidden;
          @media (max-width: 750px){
            font-size: 70px;
            margin-bottom: 80px;
            &.active{
              h1{
                font-size: 70px;
              }
            }
          }
          @media (max-width:480px){
            font-size: 50px;
            margin-bottom: 47px;
            &.active{
              h1{
                font-size: 50px;
              }
            }
          }
          @media (max-width:650px) and (max-height: 580px){
            font-size: 7vh;
            line-height: 7vh;
            margin-bottom: 5vh;
            h1{
              margin: 0;
            }
            &.active{
              h1{
                font-size: 7vh;
              }
            }
          }
          a{
            color: #7e7005;
            cursor: pointer;
            padding-right: 0;
            h1{
              font-size: 50px;
              margin: 0;
              padding: 0;
            }
          }
          &.last{
            a{
              padding-right: 0;
            }
          }
          &.active > a{
            background-color: transparent;
            font-weight: 700;
            color: #000;
          }
          &:hover{
            a{
              color: #000;
            }
            &.active > a{
              background-color: transparent;
              color: #000;
            }
          }
        }
        @media (max-height: 490px){
          margin-top: 45px;
          transform: none;
          top: 65px;
          margin-right: 0;
          right: 65px;
          li{
            margin-bottom: 10px;
          }
        }
        @media (max-height: 420px){
          margin-top: 15px;
        }
        @media (max-height: 490px){
          li{
            font-size: 35px;
            &.active{
              h1{
                font-size: 35px;
              }
            }
          }
        }
        @media (max-width: 480px){
          right: 42px;
        }
        /*
        @media (max-height: 420px){
          ${ props => props.menuopened === 'true' && css`
            margin-top: 0;
          `}
        }
        @media only screen
        and (min-device-width: 320px)
        and (max-device-width: 480px)
        and (-webkit-min-device-pixel-ratio: 2)
        and (orientation: landscape) {
          margin-right: 16px;
          li{
            &.active > a{
              h1{
                font-size: 30px;
              }
            }
          }
        }
        @media (max-height:490px) and (max-width: 750px){
          margin-top: 10px !important;
          li{
            margin-bottom: 15px;
            font-size: 30px;
          }
        }
        @media (max-height:490px) and (max-width: 896px) and (min-width: 751px){
          margin-top: 37%;
          li{
            margin-bottom: 15px;
            font-size: 30px;
          }
        }
        @media (max-height:400px) and (max-width: 896px) and (min-width: 751px){
          margin-top: 25%;
          li{
            margin-bottom: 15px;
            font-size: 30px;
          }
        }
        @media (max-height:400px) and (min-width: 897px){
          margin-top: 65px;
          li{
            margin-bottom: 15px;
            font-size: 30px;
          }
        }
        */
    `}
  }
`

export {
  StyledNavbar,
  StyledNav,
  GlobalStyle,
  StyledFooter,
  StyledSection,
  StyledApp,
  StyledWrapper,
  Inliner,
  StyledControllabel,
  StyledFormControl,
  StyledPreload,
  StyledCheckbox,
  StyledButton,
  StyledOEI,
  Logotype,
  MenuLogotypes,
  Styled404
}
