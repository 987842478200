import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  StyledSection,
  Inliner,
  StyledControllabel,
  StyledFormControl,
  StyledCheckbox,
  StyledButton
} from "../styles/Styles.js";
import { withNamespaces, NamespacesConsumer } from "react-i18next";
import { FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { Markup } from "interweave";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.usernameRef = React.createRef();
    this.emailRef = React.createRef();
    this.phoneRef = React.createRef();
    this.messageRef = React.createRef();
    this.agreedRef = React.createRef();

    this.state = {
      isSending: false,
      sendSuccess: false,
      form: {
        username: "",
        email: "",
        phone: "",
        message: "",
        agreed: false
      },
      validations: {
        usernameValid: false,
        emailValid: false,
        phoneValid: false,
        messageValid: false,
        agreedValid: false
      }
    };
  }

  componentDidMount() {
    if (this.props.contactData.length === 0) {
      axios.get("https://oedesign.pro/backend/api/contact/").then(resp => {
        this.props.onDataLoaded({ contactData: resp.data });
      });
    }
  }

  handleChangeUser = e => {
    const { form, validations } = this.state;
    let item = {
      ...form,
      username: e.target.value
    };
    let itemValidate = {
      ...validations,
      usernameValid: false
    };
    this.setState({ form: item, validations: itemValidate });
  };

  handleChangeEmail = e => {
    const { form, validations } = this.state;
    let item = {
      ...form,
      email: e.target.value
    };
    let itemValidate = {
      ...validations,
      emailValid: false
    };
    this.setState({ form: item, validations: itemValidate });
  };

  handleChangePhone = e => {
    const { form, validations } = this.state;
    let item = {
      ...form,
      phone: e.target.value
    };
    let itemValidate = {
      ...validations,
      phoneValid: false
    };
    this.setState({ form: item, validations: itemValidate });
  };

  handleChangeMessage = e => {
    const { form, validations } = this.state;
    let item = {
      ...form,
      message: e.target.value
    };
    let itemValidate = {
      ...validations,
      messageValid: false
    };
    this.setState({ form: item, validations: itemValidate });
  };

  handleChangeAgree = e => {
    const { form, validations } = this.state;
    let item = {
      ...form,
      agreed: !form.agreed
    };
    let itemValidate = {
      ...validations,
      agreedValid: false
    };
    this.setState({ form: item, validations: itemValidate });
  };

  handleSubmit = e => {
    const { validations, form, isSending } = this.state;
    const { username, email, message, agreed } = this.state.form;
    e.preventDefault();

    if (!isSending) {
      if (username.length < 6) {
        let item = {
          ...validations,
          usernameValid: true
        };
        this.setState({ validations: item });
        this.usernameRef.focus();
        return false;
      } else if (email.length < 6) {
        let item = {
          ...validations,
          emailValid: true
        };
        this.setState({ validations: item });
        this.emailRef.focus();
        return false;
      } else if (message.length < 10) {
        let item = {
          ...validations,
          messageValid: true
        };
        this.setState({ validations: item });
        this.messageRef.focus();
        return false;
      } else if (!agreed) {
        let item = {
          ...validations,
          agreedValid: true
        };
        this.setState({ validations: item });
        return false;
      } else {
        this.setState({ isSending: true });
        fetch("https://oedesign.pro/backend/api/contact/", {
          method: "post",
          body: JSON.stringify({
            ...form
          })
        })
          .then(resp => {
            if (resp.ok) {
              return resp.json();
            } else {
              throw new Error();
            }
          })
          .then(resp => {
            if (resp.response === "OK") {
              this.setState({
                ...this.state,
                sendSuccess: true,
                isSending: false,
                form: {
                  username: "",
                  email: "",
                  phone: "",
                  message: "",
                  agreed: false
                },
                validations: {
                  usernameValid: false,
                  emailValid: false,
                  phoneValid: false,
                  messageValid: false,
                  agreedValid: false
                }
              });
            }
          });
      }
    }
  };

  render() {
    const { isSending, sendSuccess } = this.state;
    const { username, email, phone, message, agreed } = this.state.form;
    const {
      usernameValid,
      emailValid,
      messageValid,
      agreedValid
    } = this.state.validations;
    const { lng, contactData } = this.props;
    let agree = "";
    let afterSend = "";

    if (lng.includes("pl")) {
      agree = <Markup content={contactData.agree_pl} />;
    } else {
      agree = <Markup content={contactData.agree_en} />;
    }
    if (sendSuccess) {
      afterSend = (
        <NamespacesConsumer>
          {(t, { i18n }) => <b className="aftersend">{t("SUCCESS_SEND")}</b>}
        </NamespacesConsumer>
      );
    }

    return (
      <StyledSection page="contact">
        <NamespacesConsumer>
          {(t, { i18n }) => (
            <React.Fragment>
              <Helmet>
                <title>
                  {t("PAGE_TITLE")} - {t("CONTACT")}
                </title>
                <meta
                  name="description"
                  content={t("PAGE_TITLE") + t("CONTACT")}
                />
              </Helmet>
            </React.Fragment>
          )}
        </NamespacesConsumer>
        <Inliner page="contact">
          <div className="flexo smaller lefto">
            <NamespacesConsumer>
              {(t, { i18n }) => <h2 className="title">{t("ADDRESS")}</h2>}
            </NamespacesConsumer>
            {contactData.address}
            <br />
            {contactData.address2}
            <br />
            {contactData.address3}
            <div className="lefto-more">
              <NamespacesConsumer>
                {(t, { i18n }) => (
                  <React.Fragment>{t("PHONE_PREFIX")} </React.Fragment>
                )}
              </NamespacesConsumer>
              <a href={"tel:" + contactData.phone}>{contactData.phone}</a>
              <br />
              m: <a href={"mailto:" + contactData.email}>{contactData.email}</a>
            </div>
          </div>
          <div className="flexo righto">
            <NamespacesConsumer>
              {(t, { i18n }) => <h1 className="title">{t("CONTACT_FORM")}</h1>}
            </NamespacesConsumer>
            <form onSubmit={this.handleSubmit}>
              <FormGroup validationState={usernameValid ? "error" : null}>
                <StyledControllabel>
                  <NamespacesConsumer>
                    {(t, { i18n }) => (
                      <React.Fragment>{t("USERNAME")}*</React.Fragment>
                    )}
                  </NamespacesConsumer>
                </StyledControllabel>
                <StyledFormControl
                  type="text"
                  inputRef={ref => {
                    this.usernameRef = ref;
                  }}
                  value={username}
                  onChange={this.handleChangeUser}
                />
                {usernameValid ? (
                  <NamespacesConsumer>
                    {(t, { i18n }) => (
                      <span className="error-info">{t("THIS_IS_REQUIRE")}</span>
                    )}
                  </NamespacesConsumer>
                ) : (
                  ""
                )}
              </FormGroup>
              <FormGroup validationState={emailValid ? "error" : null}>
                <StyledControllabel>
                  <NamespacesConsumer>
                    {(t, { i18n }) => (
                      <React.Fragment>{t("MAIL_ADDRESS")}*</React.Fragment>
                    )}
                  </NamespacesConsumer>
                </StyledControllabel>
                <StyledFormControl
                  type="text"
                  value={email}
                  inputRef={ref => {
                    this.emailRef = ref;
                  }}
                  onChange={this.handleChangeEmail}
                />
                {emailValid ? (
                  <NamespacesConsumer>
                    {(t, { i18n }) => (
                      <span className="error-info">{t("THIS_IS_REQUIRE")}</span>
                    )}
                  </NamespacesConsumer>
                ) : (
                  ""
                )}
              </FormGroup>
              <FormGroup>
                <StyledControllabel>
                  <NamespacesConsumer>
                    {(t, { i18n }) => (
                      <React.Fragment>{t("PHONE_NUMBER")}</React.Fragment>
                    )}
                  </NamespacesConsumer>
                </StyledControllabel>
                <StyledFormControl
                  type="text"
                  value={phone}
                  inputRef={ref => {
                    this.phoneRef = ref;
                  }}
                  onChange={this.handleChangePhone}
                />
              </FormGroup>
              <FormGroup validationState={messageValid ? "error" : null}>
                <StyledControllabel>
                  <NamespacesConsumer>
                    {(t, { i18n }) => (
                      <React.Fragment>{t("MESSAGE")}*</React.Fragment>
                    )}
                  </NamespacesConsumer>
                </StyledControllabel>
                <StyledFormControl
                  componentClass="textarea"
                  rows="7"
                  value={message}
                  inputRef={ref => {
                    this.messageRef = ref;
                  }}
                  onChange={this.handleChangeMessage}
                />
                {messageValid ? (
                  <NamespacesConsumer>
                    {(t, { i18n }) => (
                      <span className="error-info">{t("THIS_IS_REQUIRE")}</span>
                    )}
                  </NamespacesConsumer>
                ) : (
                  ""
                )}
              </FormGroup>
              <FormGroup validationState={agreedValid ? "error" : null}>
                <div className="form-lower">
                  <StyledCheckbox
                    inputRef={ref => {
                      this.agreedRef = ref;
                    }}
                    checked={agreed ? "checked" : ""}
                    onChange={this.handleChangeAgree}
                  >
                    <i />
                    <div
                      style={{
                        display: "inline",
                        position: "relative",
                        top: "-4px"
                      }}
                    >
                      {agree}
                    </div>
                  </StyledCheckbox>
                  {agreedValid ? (
                    <NamespacesConsumer>
                      {(t, { i18n }) => (
                        <span className="error-info">
                          {t("THIS_IS_REQUIRE")}
                        </span>
                      )}
                    </NamespacesConsumer>
                  ) : (
                    ""
                  )}
                  <br />
                  <NamespacesConsumer>
                    {(t, { i18n }) => (
                      <React.Fragment>{t("REQUIRED_FIELDS")}</React.Fragment>
                    )}
                  </NamespacesConsumer>
                </div>
                <br />
                <br />
                <StyledButton
                  type="submit"
                  bsStyle="primary"
                  formdisabled={isSending ? "disabled" : "null"}
                >
                  <NamespacesConsumer>
                    {(t, { i18n }) => (
                      <React.Fragment>{t("SUBMIT")}</React.Fragment>
                    )}
                  </NamespacesConsumer>
                </StyledButton>
                <br />
                {afterSend}
              </FormGroup>
            </form>
          </div>
        </Inliner>
      </StyledSection>
    );
  }
}

const mapStateToProps = state => ({
  menuOpened: state.menuOpened,
  pageLoading: state.pageLoading,
  contactData: state.contactData
});

const mapDispatchToProps = dispatch => ({
  onMenuOpen: e => {
    dispatch({
      type: "TOGGLE_MENU",
      payload: e
    });
  },
  onPageLoaded: e => {
    dispatch({
      type: "TOGGLE_LOADING",
      payload: e
    });
  },
  onDataLoaded: e => {
    dispatch({
      type: "UPDATE_CONTACT",
      payload: e
    });
  }
});

const TranslatedContact = withNamespaces("translation")(Contact);
const StoredContact = connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslatedContact);
export default StoredContact;
