import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { StyledSection, Inliner } from "../styles/Styles.js";
import { withNamespaces, NamespacesConsumer } from "react-i18next";
import { Markup } from "interweave";
import { connect } from "react-redux";
import axios from "axios";

class Offer extends Component {
  componentDidMount() {
    if (this.props.offerData.length === 0) {
      axios
        .get("https://oedesign.pro/backend/api/offer/")
        .then(json => this.props.onDataLoaded({ offerData: json.data }));
    }
  }

  render() {
    const { lng, offerData } = this.props;
    let container = "";
    let offerItems = [];

    if (offerData.items) {
      if (lng.includes("pl")) {
        container = <Markup content={offerData.intro.content_pl} />;
      } else {
        container = <Markup content={offerData.intro.content_en} />;
      }

      offerItems = offerData.items.map((offer, index) => (
        <Inliner
          page="offer"
          className={index === 0 ? "first" : ""}
          key={lng.includes("pl") ? offer.title_pl : offer.title_en}
        >
          <div className="flexo smaller">
            <h3>{lng.includes("pl") ? offer.title_pl : offer.title_en}</h3>
          </div>
          <div className="flexo">
            <img
              src={"https://oedesign.pro/" + offer.fileImage1 + "_big.jpg"}
              alt={lng.includes("pl") ? offer.title_pl : offer.title_en}
              className="image-center"
            />
          </div>
        </Inliner>
      ));
    }

    return (
      <StyledSection page="offer">
        <NamespacesConsumer>
          {(t, { i18n }) => (
            <React.Fragment>
              <Helmet>
                <title>
                  {t("PAGE_TITLE")} - {t("OFFER")}
                </title>
                <meta
                  name="description"
                  content={t("PAGE_TITLE") + t("OFFER")}
                />
              </Helmet>
            </React.Fragment>
          )}
        </NamespacesConsumer>
        {container}
        {offerItems}
      </StyledSection>
    );
  }
}

const mapStateToProps = state => ({
  menuOpened: state.menuOpened,
  pageLoading: state.pageLoading,
  offerData: state.offerData
});

const mapDispatchToProps = dispatch => ({
  onMenuOpen: menuOpened => {
    dispatch({
      type: "TOGGLE_MENU",
      payload: menuOpened
    });
  },
  onPageLoaded: e => {
    dispatch({
      type: "TOGGLE_LOADING",
      payload: e
    });
  },
  onDataLoaded: e => {
    dispatch({
      type: "UPDATE_OFFER",
      payload: e
    });
  }
});

const TranslatedOffer = withNamespaces("translation")(Offer);
const StoredOffer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslatedOffer);
export default StoredOffer;
