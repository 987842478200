import React, { Component } from 'react'
import { StyledSection } from '../styles/Styles.js'
import { Helmet } from 'react-helmet'
import { NamespacesConsumer } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class Home extends Component {
  constructor(props){
    super(props)

    this.state = {
      pageLoaded: false
    }
  }

  render() {
    return <StyledSection page="home">
      <NamespacesConsumer>
        {(t, { i18n }) => <React.Fragment>
          <Helmet>
            <title>{ t('PAGE_TITLE') }</title>
            <meta name="description" content={ t('PAGE_TITLE') }/>
          </Helmet>
        </React.Fragment>}
      </NamespacesConsumer>
      <div className="home-intro">
        <img src="images/oe_dp_grafika_7-min.jpg" alt="Homepage pictogram" className="image-center hide-mobile"/>
        <h1 className="logo-title">
          <NamespacesConsumer>
            {(t, { i18n }) => <React.Fragment> {t('WEARE')}</React.Fragment>}
          </NamespacesConsumer>
          <br/>
          <NamespacesConsumer>
            {(t, { i18n }) => <React.Fragment> {t('WEARE2')}</React.Fragment>}
          </NamespacesConsumer>
        </h1>
      </div>
    </StyledSection>
  }
}

const mapStateToProps = state => ({
  user: state.user,
  pageLoading: state.pageLoading
})

const mapDispatchToProps = dispatch => ({
  onMenuOpen: (e) => {
    dispatch({
      type: 'OPEN_MENU',
      payload: e,
    })
  },
  onPageLoaded: (e) => {
    dispatch({
      type: 'TOGGLE_LOADING',
      payload: e,
    })
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))
