import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'
import translationEN from './locales/en/translation.json'
import translationPL from './locales/pl/translation.json'

const resources = {
  en: {
    translation: translationEN
  },
  pl: {
    translation: translationPL
  }
}

const options = {
  order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'],
  cookieMinutes: 10,
  cookieDomain: 'oedesign'
}

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    resources,
    debug: false,
    detection: options,
    load: 'unspecific',
    whitelist: ['pl', 'en'],
    nonExplicitWhitelist: true,
    lowerCaseLng: true,
    fallbackLng: {
      default: ['pl']
    },
    keySeparator: false
  })

  export default i18n
