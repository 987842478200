import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import { StyledFooter, StyledOEI } from '../styles/Styles.js'
import { NamespacesConsumer } from 'react-i18next'

const CurrentYear = () => {
  let year = new Date().getFullYear()
  return year
}

class Footer extends Component {
  render() {
    const { page } = this.props
    return <StyledFooter page={ page }>
      <Grid fluid>
        <Row className="show-grid">
          <Col xs={ 6 } md={ 6 } className="first-footer">
            &copy; <CurrentYear/>
            <NamespacesConsumer>
              { (t, { i18n }) => <React.Fragment> { t('COPY') }</React.Fragment> }
            </NamespacesConsumer>
          </Col>
          <Col xs={ 6 } md={ 6 } className="second-footer">
            <a href="https://oeindustry.com/" target="_blank" rel="noopener noreferrer nofollow"><StyledOEI className="testowo"/></a>
          </Col>
        </Row>
      </Grid>
    </StyledFooter>
  }
}

export default Footer
