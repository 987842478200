import React, { Component } from 'react'
import { StyledNavbar, StyledNav, Logotype, MenuLogotypes } from '../styles/Styles.js'
import { Link, withRouter } from 'react-router-dom'
import { Navbar, NavItem } from 'react-bootstrap'
import { IndexLinkContainer } from 'react-router-bootstrap'
import { withNamespaces, NamespacesConsumer } from 'react-i18next'
import { connect } from 'react-redux'

let lastScrollY = 0
let ticking = false

class ElementNavbar extends Component {
  constructor(props) {
    super(props)

    this.props.history.listen((location, action) => {
      window.scrollTo(0, 0)
    })

    this.state = {
      scrollPos: 0
    }
 }

 toggleMenu = (e) => {
   let screenWidth = window.innerWidth
   if(screenWidth < 700 || (e.target.parentNode.classList.contains('navbar-toggle') || e.target.classList.contains('navbar-toggle')) || this.props.menuOpened){
     this.props.onMenuOpen({ menuOpened: !this.props.menuOpened })
   }
   if(this.props.menuOpened){
     this.props.onMenuOpen({ menuClosing: true })
     setTimeout(() => this.props.onMenuOpen({ menuClosing: false }), 300)
   }
 }

 componentDidMount(){
   window.addEventListener('scroll', this.handleScroll)
 }

 componentWillUnmount() {
   window.removeEventListener('scroll', this.handleScroll)
 }

 handleScroll = () => {
   lastScrollY = window.scrollY
   if (!ticking) {
     window.requestAnimationFrame(() => {
       this.setState({ scrollPos: lastScrollY })
       ticking = false
     })
     ticking = true
   }
 }


 changeLanguage = (lng) => {
   let screenWidth = window.innerWidth
   const { location } = this.props

   this.props.i18n.changeLanguage(lng)
   if(screenWidth >= 700){
     this.props.onMenuOpen({ menuOpened: !this.props.menuOpened, menuClosing: true })
     setTimeout(() => this.props.onMenuOpen({ menuClosing: false }), 300)
   } else {
     if(location.pathname !== '/'){
       this.props.onMenuOpen({ menuOpened: false, menuClosing: true })
       setTimeout(() => this.props.onMenuOpen({ menuClosing: false }), 300)
     }
   }
   //window.scrollTo(0, window.pageYOffset + 1)
   window.scrollTo(0, 0)
 }

 render() {
   const { lng, menuOpened, menuClosing } = this.props
   const { scrollPos } = this.state
   const { pathname } = this.props.location

   let scrolling = false, scrolling2 = false, scrolling3 = false
   let menuContainer = ''
   let menuElement, menuElement2

   if(scrollPos < 2){
     scrolling = false
   } else {
     scrolling = true
   }
   if(scrollPos < 90){
     scrolling2 = false
   } else {
     scrolling2 = true
   }
   if(scrollPos < 12){
     scrolling3 = false
   } else {
     scrolling3 = true
   }

   if(pathname === '/about-us' || pathname === '/o-nas'){
     menuElement = <NamespacesConsumer>
       {(t, { i18n }) => <h1>{ t('ABOUTUS') }</h1>}
     </NamespacesConsumer>
   } else {
     menuElement = <NamespacesConsumer>
       {(t, { i18n }) => <React.Fragment>{ t('ABOUTUS') }</React.Fragment>}
     </NamespacesConsumer>
   }

   if(pathname === '/services' || pathname === '/uslugi'){
     menuElement2 = <NamespacesConsumer>
       {(t, { i18n }) => <h1>{ t('OFFER') }</h1>}
     </NamespacesConsumer>
   } else {
     menuElement2 = <NamespacesConsumer>
       {(t, { i18n }) => <React.Fragment>{ t('OFFER') }</React.Fragment>}
     </NamespacesConsumer>
   }

   menuContainer = <StyledNav pullRight menuopened={ menuOpened ? 'true' : 'false' } sticky={ scrolling3 ? 'true' : 'false' }>
     <IndexLinkContainer to={ lng.includes('pl') ? "/o-nas" : "/about-us" } activeClassName="active" onClick={ this.toggleMenu }>
       <NavItem eventKey={ 1 }>
         { menuElement }
       </NavItem>
     </IndexLinkContainer>
     <IndexLinkContainer to={ lng.includes('pl') ? "/uslugi" : "/services" } activeClassName="active" onClick={ this.toggleMenu }>
       <NavItem eventKey={ 2 }>
         { menuElement2 }
       </NavItem>
     </IndexLinkContainer>
     <IndexLinkContainer to={ lng.includes('pl') ? "/kontakt" : "/contact" } activeClassName="active" onClick={ this.toggleMenu }>
       <NavItem eventKey={ 3 } className="last">
         <NamespacesConsumer>
           {(t, { i18n }) => <React.Fragment>{ t('CONTACT') }</React.Fragment>}
         </NamespacesConsumer>
       </NavItem>
     </IndexLinkContainer>
   </StyledNav>


   return (
     <StyledNavbar inverse collapseOnSelect fluid menuopened={ menuOpened ? 'true' : 'false' } sticky={ scrolling2 ? 'true' : 'false' } sticky2={ scrolling3 ? 'true' : 'false' } closing={ menuClosing ? 'true' : 'false' }>
       <Navbar.Header>
         <Navbar.Brand style={{ 'paddingTop': '20px', 'cursor': 'pointer' }}>
           <Link to="/"><Logotype sticky={ scrolling }/></Link>
         </Navbar.Brand>
         <Navbar.Toggle onClick={ this.toggleMenu }/>
       </Navbar.Header>
       <Navbar.Collapse>
         <div className="menu-container">
           <div className="language-switch" menuopened={ menuOpened ? 'true' : 'false' }>
             <button className={ lng.includes('pl') ? 'current' : '' } onClick={() => this.changeLanguage('pl')}>pl</button>
             <button className={ lng.includes('en') ? 'current' : '' } onClick={() => this.changeLanguage('en')}>en</button>
           </div>
           { menuContainer }
           <MenuLogotypes menuopened={ menuOpened ? 'true' : 'false' }>
             <a href="http://www.oedesign.pro/" target="_blank" rel="noopener noreferrer nofollow"><div className="menu-logotype"></div></a>
             <a href="https://oeindustry.com/" target="_blank" rel="noopener noreferrer nofollow"><div className="menu-logotype second"></div></a>
           </MenuLogotypes>
         </div>
       </Navbar.Collapse>
     </StyledNavbar>
    )
  }
}

const mapStateToProps = state => ({
  menuOpened: state.menuOpened,
  pageLoading: state.pageLoading,
  menuClosing: state.menuClosing
})

const mapDispatchToProps = dispatch => ({
  onMenuOpen: (e) => {
    dispatch({
      type: 'TOGGLE_MENU',
      payload: e
    })
  },
  onPageLoaded: (e) => {
    dispatch({
      type: 'TOGGLE_LOADING',
      payload: e,
    })
  }
})

const NavbarTranslated = withNamespaces('translation')(ElementNavbar)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavbarTranslated))
